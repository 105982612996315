import { createSignalAction, createActionCreator } from "shared/reduxUtils"

const BASE = "SEARCH"
export const SAVE_REQ_PARAMS = "SAVE_REQ_PARAMS"
export const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER"
export const OPEN_INQUIRY_FORM = "OPEN_INQUIRY_FORM"
export const CLOSE_INQUIRY_FORM = "CLOSE_INQUIRY_FORM"

export const fetchAgentsAction = createSignalAction(BASE, "FETCH_AGENTS")
export const sendAppLinkToDownloadAction = createSignalAction(BASE, "SEND_APP_LINK_TO_DOWNLOAD")
export const submitInquiryAction = createSignalAction(BASE, "SUBMIT_INQUIRY")

export const saveReqParams = createActionCreator(SAVE_REQ_PARAMS)
export const updatePhoneNumber = createActionCreator(UPDATE_PHONE_NUMBER)
export const openInquiryForm = createActionCreator(OPEN_INQUIRY_FORM)
export const closeInquiryForm = createActionCreator(CLOSE_INQUIRY_FORM)
