import React, { Component } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Transition from "react-transition-group/Transition"

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background: rgba(100, 100, 100, 0.9);
`

const Dialog = styled.div`
  width: 600px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IconContainer = styled.div`
  position: relative;
`

const StyledFontAwesome = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #dadada;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
`

const Content = styled.div`
  overflow: auto;
  padding: 24px;
`

const defaultStyle = {
  transition: "opacity 150ms ease-in-out",
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exited: { opacity: 0 },
}

class Modal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      if (this.props.show) {
        document.body.classList.add("noscroll")

        if (this.props.closeModalOnEscape) {
          document.addEventListener("keydown", this._handleKeydown, false)
        }
      } else {
        document.body.classList.remove("noscroll")

        if (this.props.closeModalOnEscape) {
          document.removeEventListener("keydown", this._handleKeydown, false)
        }
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("noscroll")

    if (this.props.closeModalOnEscape) {
      document.removeEventListener("keydown", this._handleKeydown, false)
    }
  }

  _handleKeydown = (e) => {
    if (e.keyCode === 27) {
      this.props.toClose()
    }
  }

  handleOutsideClick = () => {
    if (this.props.closeModalOnOutsideClick) {
      this.props.toClose()
    }
  }

  handleModalClick = (e) => {
    e.stopPropagation()
  }

  render() {
    const {
      toClose, showCloseButton, children, show,
    } = this.props
    console.log(this.props)
    return (
      <Transition in={show} timeout={150} mountOnEnter unmountOnExit>
        {state => (
          <Overlay onClick={this.handleOutsideClick} style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <Dialog onClick={this.handleModalClick}>
              {showCloseButton && (
                <IconContainer>
                  <StyledFontAwesome icon="times" onClick={toClose} />
                </IconContainer>
              )}

              <Content>{children}</Content>
            </Dialog>
          </Overlay>
        )}
      </Transition>
    )
  }
}

Modal.defaultProps = {
  closeModalOnOutsideClick: true,
  closeModalOnEscape: true,
  showCloseButton: true,
}

export default Modal
