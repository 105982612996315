import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as SearchActions from "./actions"

const mapStateToProps = (state) => {
  const { searchAgents } = state
  const {
    agents,
    agentsTotalCount,
    facades,
    request,
    isFetchingAgents,
    isFilterFetching,
    phone,
    countryCode,
    isSendingAppLink,
    isSubmittingInquiry,
    isSubmitInquiryOpen,
    selectedAgentId,
  } = searchAgents
  return {
    agents,
    agentsTotalCount,
    facades,
    request,
    isFetchingAgents,
    isFilterFetching,
    phone,
    countryCode,
    isSendingAppLink,
    isSubmittingInquiry,
    isSubmitInquiryOpen,
    selectedAgentId,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchAgents: SearchActions.fetchAgentsAction.request,
      sendAppLinkToDownload: SearchActions.sendAppLinkToDownloadAction.request,
      submitInquiry: SearchActions.submitInquiryAction.request,
      updatePhoneNumber: SearchActions.updatePhoneNumber,
      openInquiryForm: SearchActions.openInquiryForm,
      closeInquiryForm: SearchActions.closeInquiryForm,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
