import styled from "styled-components"

export const Form = styled.form`
  padding: ${props => props.padding};
`

export const FormGroup = styled.div`
  text-align: ${props => props.align};
  margin-bottom: 15px;
  padding: 3px;
  box-sizing: border-box;
`
export const FormLabel = styled.label`
  color: #354052;
  font-size: 14px;
  text-align: left;
  display: block;
  line-height: 1.75;
  font-weight: 300;
`

export const Textarea = styled.textarea`
  width: 100%;
  border: 0.5px solid #d4d4d4;
  box-sizing: border-box;
  box-shadow: none;
  font-size: 16px;
  &:focus,
  &:active {
    box-shadow: none;
    border: solid 0.5px #11adf3;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc !important;
    font-weight: 300;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc !important;
    font-weight: 300;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc !important;
    font-weight: 300;
  }
`
