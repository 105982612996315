import React, { Component } from "react"
import styled from "styled-components"
import {
  Form, FormGroup, FormLabel, Textarea,
} from "dumbComponents/common/UI/FormElements"
import { parseInquiry } from "services/Utils"
import Container from "container/SearchAgents"
import Textbox from "dumbComponents/common/UI/Textbox"
import Button from "dumbComponents/common/UI/Button"
import PhoneNumber from "dumbComponents/common/UI/PhoneNumber"
import Heading from "dumbComponents/common/Typography/Heading"
import { Flex, Box } from "@rebass/grid"

const Wrapper = styled.div`
  position: relative;
`
const Required = styled.span`
  color: red;
`
const ButtonGroup = styled(Flex)`
  flex-flow: row nowrap;
`

const StyledPhoneNumber = styled(PhoneNumber)`
  height: 40px;
`

class ContactMe extends Component {
  state = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    notes: "",
    type: "Agent",
  }

  submitInquiry = (event) => {
    event.preventDefault()
    const { selectedAgentId, submitInquiry } = this.props
    const data = parseInquiry(this.state, selectedAgentId)
    console.log(data)
    submitInquiry(data)
  }

  render() {
    const {
      firstname, lastname, email, phone, notes, type,
    } = this.state
    return (
      <Wrapper>
        <Heading type="h4" font="Rubik" center={false} weight={300}>
          Contact Agent
        </Heading>
        <Form onSubmit={this.submitInquiry}>
          <FormGroup>
            <Flex>
              <Box width={[1, 1, 1 / 2]} mr={[0, 0, "10px"]}>
                <FormLabel>
                  First name
                  <Required>&#x2A;</Required>
                </FormLabel>
                <Textbox
                  id="firstname"
                  value={firstname}
                  onChange={event => this.setState({ firstname: event.target.value })}
                  required
                />
              </Box>
              <Box width={[1, 1, 1 / 2]} ml={[0, 0, "10px"]}>
                <FormLabel>Last name</FormLabel>
                <Textbox
                  id="lastname"
                  value={lastname}
                  onChange={event => this.setState({ lastname: event.target.value })}
                />
              </Box>
            </Flex>
          </FormGroup>
          <FormGroup>
            <ButtonGroup>
              <Button
                type="button"
                bsStyle={type === "Agent" ? "primary" : "secondary"}
                borderRadius="0"
                onClick={() => this.setState({ type: "Agent" })}
              >
                Agent
              </Button>
              <Button
                type="button"
                bsStyle={type === "Buyer" ? "primary" : "secondary"}
                borderRadius="0"
                onClick={() => this.setState({ type: "Buyer" })}
              >
                Buyer
              </Button>
              <Button
                type="button"
                bsStyle={type === "Seller" ? "primary" : "secondary"}
                borderRadius="0"
                onClick={() => this.setState({ type: "Seller" })}
              >
                Seller
              </Button>
            </ButtonGroup>
          </FormGroup>
          <FormGroup>
            <Flex>
              <Box width={[1, 1, 1 / 2]} mr={[0, 0, "10px"]}>
                <FormLabel>
                  Email
                  <Required>&#x2A;</Required>
                </FormLabel>
                <Textbox
                  type="email"
                  id="email"
                  value={email}
                  onChange={event => this.setState({ email: event.target.value })}
                  required
                />
              </Box>
              <Box width={[1, 1, 1 / 2]} ml={[0, 0, "10px"]}>
                <FormLabel>Phone number</FormLabel>
                <StyledPhoneNumber
                  id="phone"
                  phone={phone}
                  height="40px"
                  border="0.5px solid #d4d4d4"
                  onPhoneNumberChange={event => this.setState({ phone: event.target.rawValue })}
                />
              </Box>
            </Flex>
          </FormGroup>
          <FormGroup>
            <Flex>
              <Box width="100%">
                <FormLabel>Message</FormLabel>
                <Textarea rows="8" value={notes} onChange={event => this.setState({ notes: event.target.value })} />
              </Box>
            </Flex>
          </FormGroup>
          <FormGroup>
            <Flex justifyContent="center">
              <Button>Submit</Button>
            </Flex>
          </FormGroup>
        </Form>
      </Wrapper>
    )
  }
}
export default Container(ContactMe)
