const colors = {
  primaryColor: "#2684FF",
  primaryHover: "#ffffff",
  disabledColor: "#e7ebed",
  infoBorderColor: "#10adf3",
  white: "#ffffff",
  borderColor: "#e7e8ea",
}

export default colors
